import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Animations {
   init = async () => {
      AOS.init({
         once: true,
         duration: 500,
         easing: 'ease-in-out',
      });
   };
}
