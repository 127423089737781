import gsap from 'gsap';

export default class Menu {
   private hamburger: HTMLElement | null;
   private menuWrapper: HTMLElement | null;
   private isOpen: boolean;
   private hamburgerTimeLine: gsap.core.Timeline;

   constructor() {
      this.hamburger = document.querySelector<HTMLElement>('.hamburger');
      this.menuWrapper = document.querySelector<HTMLElement>('.header__nav');
      this.isOpen = false;
      this.hamburgerTimeLine = this.getHamburgerTimeLine();
   }

   init = async () => {
      this.hamburger?.addEventListener('click', this.toggleMenu);
   };

   toggleMenu = () => {
      if (!this.menuWrapper) return;

      this.isOpen = !this.isOpen;

      this.isOpen ? this.hamburgerTimeLine.play() : this.hamburgerTimeLine.reverse();

      this.menuWrapper.classList.toggle('header__nav--open');
   };

   getHamburgerTimeLine = () => {
      const tl = gsap.timeline({ paused: true });

      if (!this.hamburger) return tl;

      const hamburgerParts = this.hamburger?.querySelectorAll<HTMLElement>('span');

      tl.to(hamburgerParts[0], { y: 8.7, duration: 0.2 }, 0);
      tl.to(hamburgerParts[2], { opacity: 0, duration: 0.2 }, 0);
      tl.to(hamburgerParts[1], { rotate: 45, duration: 0.1 }, 0.2);
      tl.to(hamburgerParts[0], { rotate: -45, duration: 0.1 }, 0.2);

      return tl;
   };
}
