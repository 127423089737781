import LazyLoad from './libs/LazyLoad.lib';
import initSection from './utlis/initSection';
import Realizations from './sections/Realizations';
import CarouselSlider from './sections/CarouselSlider';
import Select from './components/Select';
import Header from './components/Header';
import Animations from './components/Animations';
import FormHandler from './components/FormHandler';
import ShowMoreHandler from './components/formComponents/ShowMoreHandler';
import NewsletterCheckboxHandler from './components/formComponents/NewsletterCheckboxHandler';

document.addEventListener('DOMContentLoaded', () => {
   const lazyLoad = new LazyLoad();
   new Header().init();
   new Animations().init();

   window.addEventListener('updateLazy', () => lazyLoad.update());

   initSection('.section-realizations', Realizations);
   initSection('.carousel_slider', CarouselSlider);
   initSection('.custom-select', Select);
   initSection('.wpcf7-form', FormHandler);
   initSection('.newsletter', ShowMoreHandler);
   initSection('.newsletter', NewsletterCheckboxHandler);
});
