type ClassType<T> = { new (...args: any[]): T };

const initSectionClass = async (className: string, classConstructor: ClassType<{ init: () => void }>) => {
   const sections = document.querySelectorAll<HTMLElement>(className);

   sections.forEach((section) => {
      new classConstructor(section).init();
   });
};

export default initSectionClass;
