import { Splide } from '@splidejs/splide';
import '@splidejs/splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export const realiztionsSlider = (sliderWrapper: HTMLElement, slidesCount: number) => {
   const actLikeSlider = (count: number): boolean => {
      if (!slidesCount) return true;
      return count < slidesCount ? true : false;
   };

   new Splide(sliderWrapper, {
      perPage: 4,
      arrows: actLikeSlider(4),
      pagination: actLikeSlider(4),
      drag: false,
      gap: 24,
      breakpoints: {
         1366: {
            perPage: 3,
            arrows: actLikeSlider(3),
            drag: actLikeSlider(3),
         },
         1024: {
            gap: 12,
         },
         850: {
            perPage: 2,
            arrows: actLikeSlider(2),
            gap: 24,
            drag: actLikeSlider(2),
         },
         580: {
            perPage: 1,
            arrows: actLikeSlider(1),
            gap: 24,
            drag: actLikeSlider(1),
         },
      },
   }).mount();
};

export const carouselSlider = (sliderWrapper: HTMLElement) => {
   new Splide(sliderWrapper, {
      type: 'loop',
      perPage: 8,
      gap: 22,
      arrows: false,
      pagination: false,
      breakpoints: {
         1440: {
            perPage: 7,
         },
         1280: {
            perPage: 6,
         },
         1024: {
            perPage: 5,
         },
         800: {
            perPage: 4,
         },
         580: {
            perPage: 3,
         },
         420: {
            perPage: 2,
         },
      },
   })
      .on('mounted', () => {
         const event = new Event('updateLazy');

         window.dispatchEvent(event);
      })
      .mount({ AutoScroll });
};
