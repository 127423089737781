import FileHandler from './formComponents/FileHandler';
import ShowMoreHandler from './formComponents/ShowMoreHandler';

export default class FormHandler {
   private filesInputLabels: NodeListOf<HTMLLabelElement>;
   private showMoreWrapper: NodeListOf<HTMLDivElement>;

   constructor(private root: HTMLElement) {
      this.filesInputLabels = this.root.querySelectorAll<HTMLLabelElement>('.file');
      this.showMoreWrapper = this.root.querySelectorAll<HTMLDivElement>('.show-more-wrapper');
   }

   public init = async () => {
      this.filesInputLabels.forEach((item) => new FileHandler(item).init());
      this.showMoreWrapper.forEach((item) => new ShowMoreHandler(item).init());
   };
}
