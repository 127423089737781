import { realiztionsSlider } from '../components/sliders';

export default class Realizations {
   private sliderElement: HTMLElement | null;

   constructor(private root: HTMLElement) {
      this.sliderElement = this.root.querySelector('.splide');
   }

   public init = async () => {
      if (!this.sliderElement) return;

      realiztionsSlider(this.sliderElement, this.sliderElement.querySelectorAll('li').length);
   };
}
