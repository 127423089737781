export default class NewsletterCheckboxHandler {
   private checkboxCheckedState: boolean;
   private checkboxWrapper: HTMLElement | null;
   private submitButton: HTMLButtonElement | null;
   private readonly classesToNotToggle: Array<string> = ['show-more'];
   private readonly checkedClassName: string = 'show-more-wrapper--checked';

   constructor(private wrapper: HTMLElement) {
      this.checkboxCheckedState = true;
      this.checkboxWrapper = this.wrapper.querySelector<HTMLDivElement>('.show-more-wrapper');
      this.submitButton = this.wrapper.querySelector<HTMLButtonElement>('.form_subscribe_button');
   }

   init = async () => {
      this.setButtonState();
      this.checkboxWrapper?.addEventListener('click', this.handleCheckboxClick);
   };

   handleCheckboxClick = (event: Event) => {
      const target = event.target as HTMLElement;
      if (this.classesToNotToggle.includes(target.className)) return;

      this.checkboxCheckedState = !this.checkboxCheckedState;
      this.checkboxWrapper?.classList.toggle(this.checkedClassName);
      this.setButtonState();
   };

   setButtonState = () => {
      if (!this.submitButton) return;

      this.submitButton.disabled = this.checkboxCheckedState;
   };
}
