import SlimSelect from 'slim-select';
import '../../../../node_modules/slim-select/dist/slimselect.css';

export default class Select {
   constructor(private root: HTMLSelectElement) {}

   public init = async () => {
      new SlimSelect({
         select: this.root,
         settings: {
            showSearch: false,
         },
      });
   };
}
