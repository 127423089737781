export default class ShowMoreHandler {
   private showMoreButton: HTMLButtonElement | null;
   private hiddenArea: HTMLDivElement | null;
   private showMoreButtonContent: string | null;

   constructor(private wrapper: HTMLElement) {
      this.showMoreButton = this.wrapper.querySelector('.show-more');
      this.hiddenArea = this.wrapper.querySelector('.hidden-area');
      this.showMoreButtonContent = this.showMoreButton?.innerText || null;
   }

   init = async () => {
      this.showMoreButton?.addEventListener('click', this.handleShowMoreClick);
   };

   handleShowMoreClick = (event: Event) => {
      event.preventDefault();
      this.hiddenArea?.classList.toggle('hidden-area--visible');

      if (!this.showMoreButtonContent || !this.showMoreButton) return;

      this.hiddenArea?.classList.contains('hidden-area--visible')
         ? (this.showMoreButton.innerText = window.global_vars.show_less)
         : (this.showMoreButton.innerText = this.showMoreButtonContent);
   };
}
