import Menu from './Menu';

export default class Header {
   private header: HTMLElement;
   private events: { [key: string]: Event };
   private scrolled: boolean;
   readonly SCROLLED_CLASS: string = 'header--scrolled';

   constructor() {
      this.header = document.querySelector<HTMLElement>('.header') as HTMLElement;
      this.events = {};
      this.scrolled = false;
   }

   public init = async () => {
      this.events['header-scroll'] = new Event('header-scroll');
      this.events['header-back'] = new Event('header-back');

      new Menu().init();

      window.addEventListener('scroll', this.handleScroll);
   };

   public handleScroll = async () => {
      if (!this.header) return;

      this.header.addEventListener('header-scroll', () => {
         this.header.classList.add(this.SCROLLED_CLASS);
      });

      this.header.addEventListener('header-back', () => {
         this.header.classList.remove(this.SCROLLED_CLASS);
      });

      window.addEventListener('scroll', () => {
         let scrollState = this.scrolled;
         if (scrollY > 20 && this.scrolled === false) scrollState = true;
         else if (scrollY < 20 && this.scrolled === true) scrollState = false;

         if (scrollState === this.scrolled) return;

         this.scrolled = scrollState;
         this.header.dispatchEvent(this.scrolled ? this.events['header-scroll'] : this.events['header-back']);
      });
   };
}
